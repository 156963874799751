import React from 'react';
import { useAuth } from './auth/AuthProvider';
import './Header.css';

function Header() {
  const time = new Date().toISOString().slice(11, 19);
  const auth = useAuth();

  return (
    <div className='Header'>
      <div className='Header__content'>
        <header>Torn Tracker</header>

        <div className='Header__stats'>
          <span>Logged in as {auth.authToken?.name}[{auth.authToken?.id}]</span>
          <span>Current TCT: {time}</span>
          <span>Last data: {time}</span>
          <a className='Header__logout' onClick={() => {window.localStorage.removeItem('torn-key'); window.localStorage.removeItem('auth-token'); auth.setAuthToken(null)}}>Logout</a>
        </div>
      </div>
    </div>
  );
}

export default Header;
