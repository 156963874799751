import React from 'react';
import { Tooltip } from 'react-tooltip';
import './StateBar.css';
import { State } from './types';

function StateBar({state, start, end, tooltipID}: StateBarProps) {

  let className = 'StateBar StateBar--okay my-anchor-element';

  switch(state.state) {
    case 'Okay': 
      className = 'StateBar StateBar--okay my-anchor-element';
      break;
    case 'Traveling': 
      className = 'StateBar StateBar--traveling my-anchor-element';
      break;
    case 'Abroad': 
      className = 'StateBar StateBar--abroad my-anchor-element';
      break;
    case 'Hospital': 
      className = 'StateBar StateBar--hospital my-anchor-element';
      break;
  }

  const tooltip = state.startTimestamp + ' - ' + state.endTimestamp + ' : ' + state.state + (state.description != undefined ? ` - ${state.description}` : '') + (state.details != undefined ? ` - ${state.details.replace(/<.+?>/g, '')}` : '');

  return (
    <div className={className} style={{width: (end - start) + '%'}} data-tooltip-id={tooltipID} data-tooltip-content={tooltip}>
    </div>
  );
}

export default StateBar;

interface StateBarProps {
  state: State;
  start: number;
  end: number;
  tooltipID: string;
}