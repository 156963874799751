import React from 'react';
import './UserDetails.css';

function UserDetails() {

  return (
    <div className='UserDetails'>
      :)
    </div>
  );
}

export default UserDetails;
