import React, { useState } from 'react';
import StateBar from './StateBar';
import { scaleTime } from 'd3-scale';
import './User.css';
import { User } from './types';
import ActivityBar from './ActivityBar';
import { Tooltip } from 'react-tooltip';
import UserDetails from './UserDetails';

function User({user}: {user: User}) {

  const [showDetails, setShowDetails] = useState(false);

  const tooltipID = `${user.name}-tooltip`;

  //let startTimestamp = new Date();
  //startTimestamp.setDate(startTimestamp.getDate() - 1);
  let startTimestamp = new Date('2023-03-04T03:00:00.000Z');
  
  let endTimestamp = new Date();

  let scale = scaleTime()
    .domain([startTimestamp, endTimestamp])
    .range([0, 100])
    .clamp(true);

  let activityBars = user.activity.map((activity) => {
    let start = scale(new Date(activity.startTimestamp));
    let end = activity.endTimestamp != null ? scale(new Date(activity.endTimestamp)) : scale(endTimestamp);

    return <ActivityBar activity={activity} start={start} end={end} tooltipID={tooltipID} key={start} />;
  });
  
  let stateBars = user.states.map((state) => {
    let start = scale(new Date(state.startTimestamp));
    let end = state.endTimestamp != null ? scale(new Date(state.endTimestamp)) : scale(endTimestamp);

    return <StateBar state={state} start={start} end={end} tooltipID={tooltipID} key={start} />;
  });

  return (
    <div className='User'>
      <div className='User__expand' onClick={() => setShowDetails(!showDetails)}>{showDetails ? <span>&#x25BC;</span> : <span>&#x25B6;</span>}</div>
      <div className='User__name'>
        {user.name}
      </div>
      <div className='User__data'>
        <div className='User__bars'>
          <div className='User__activity-bars'>
            {activityBars}
          </div>
          <div className='User__state-bars'>
            {stateBars}
          </div>
          <Tooltip id={`${user.name}-tooltip`} />
        </div>
        {showDetails ? <UserDetails  /> : null}
      </div>
    </div>
  );
}

export default User;
