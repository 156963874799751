import React from 'react';
import { TooltipWrapper } from 'react-tooltip';
import './ActivityBar.css';
import { Activity } from './types';

function ActivityBar({activity, start, end, tooltipID}: ActivityBarProps) {

  let className = 'ActivityBar ActivityBar--' + activity.status;

  const tooltip = activity.startTimestamp + ' - ' + activity.endTimestamp + ' : ' + activity.status;

  return (
    <div className={className} style={{width: (end - start) + '%'}} data-tooltip-id={tooltipID} data-tooltip-content={tooltip}>
    </div>
  );
}

export default ActivityBar;

interface ActivityBarProps {
  activity: Activity;
  start: number;
  end: number;
  tooltipID: string;
}