import React, { useEffect, useState } from 'react';
import { useAuth } from '../auth/AuthProvider';
import Loader from '../Loader';
import User from '../User';
import './FactionStats.css';

function FactionStats() {
  const [userStats, setUserStats] = useState([]);
  const [currentUserStats, setCurrentUserStats] = useState<JSX.Element[]>([]);
  const [error, setError] = useState(false);
  const auth = useAuth();

  useEffect(() => {

    const fetchData = async () => {
      console.log('Running fetchData');
      let dataRes = await fetch('https://torn-tracker-api.azure-api.net/torn-tracker-api/factions/42055/userStats', {
        headers: { 'authorization': auth.authToken!.token}
      });

      let key = auth.tornKey;
      let userRes = await fetch(`https://api.torn.com/user?selections=basic,personalstats,battlestats&key=${key}`);
      
      
      if(dataRes.ok && userRes.ok) {
        let data = await dataRes.json();
        let user = await userRes.json();

        let table = data.map(d => <tr key={d.id}>
          <td><a href={`https://www.torn.com/profiles.php?XID=${d.id}`} target='_blank'>{d.name} [{d.id}]</a></td>
          <td style={{color: calcColour(d.level, user.level)}}>{d.level}</td>
          <td style={{color: calcColour(d.stats.xantaken, user.personalstats.xantaken)}}>{d.stats.xantaken.toLocaleString('en-US')}</td>
          <td style={{color: calcColour(d.stats.cantaken, user.personalstats.cantaken)}}>{d.stats.cantaken.toLocaleString('en-US')}</td>
          <td style={{color: calcColour(d.stats.lsdtaken, user.personalstats.lsdtaken)}}>{d.stats.lsdtaken.toLocaleString('en-US')}</td>
          <td style={{color: calcColour(d.stats.refills, user.personalstats.refills)}}>{d.stats.refills.toLocaleString('en-US')}</td>
          <td style={{color: calcColour(d.stats.networth, user.personalstats.networth)}}>${d.stats.networth.toLocaleString('en-US')}</td>
          <td style={{color: calcColour(d.stats.rankedwarhits, user.personalstats.rankedwarhits)}}>{d.stats.rankedwarhits.toLocaleString('en-US')}</td>
          <td style={{color: (d.battleStats && d.battleStats.strength > -1 ? calcColour(d.battleStats.strength, user.strength) : '#fff')}}>{(d.battleStats && d.battleStats.strength > -1 ? d.battleStats.strength.toLocaleString('en-US') : '')}</td>
          <td style={{color: (d.battleStats && d.battleStats.defense > -1 ? calcColour(d.battleStats.defense, user.defense) : '#fff')}}>{(d.battleStats && d.battleStats.defense > -1 ? d.battleStats.defense.toLocaleString('en-US') : '')}</td>
          <td style={{color: (d.battleStats && d.battleStats.speed > -1 ? calcColour(d.battleStats.speed, user.speed) : '#fff')}}>{(d.battleStats && d.battleStats.speed > -1 ? d.battleStats.speed.toLocaleString('en-US') : '')}</td>
          <td style={{color: (d.battleStats && d.battleStats.dexterity > -1 ? calcColour(d.battleStats.dexterity, user.dexterity) : '#fff')}}>{(d.battleStats && d.battleStats.dexterity > -1 ? d.battleStats.dexterity.toLocaleString('en-US') : '')}</td>
          <td style={{color: (d.battleStats && d.battleStats.total > -1 ? calcColour(d.battleStats.total, user.total) : '#fff')}}>{(d.battleStats && d.battleStats.total > -1 ? d.battleStats.total.toLocaleString('en-US') : '')}</td>
        </tr>);

        setCurrentUserStats([
          <td>{user.name} [{user.player_id}]</td>,
          <td>{user.level}</td>,
          <td>{user.personalstats.xantaken.toLocaleString('en-US')}</td>,
          <td>{user.personalstats.cantaken.toLocaleString('en-US')}</td>,
          <td>{user.personalstats.lsdtaken.toLocaleString('en-US')}</td>,
          <td>{user.personalstats.refills.toLocaleString('en-US')}</td>,
          <td>${user.personalstats.networth.toLocaleString('en-US')}</td>,
          <td>{user.personalstats.rankedwarhits.toLocaleString('en-US')}</td>,
          <td>{user.strength.toLocaleString('en-US')}</td>,
          <td>{user.defense.toLocaleString('en-US')}</td>,
          <td>{user.speed.toLocaleString('en-US')}</td>,
          <td>{user.dexterity.toLocaleString('en-US')}</td>,
          <td>{user.total.toLocaleString('en-US')}</td>
        ]);

        setUserStats(table);

      } else {
        setError(true);
      } 
    }

    fetchData();
    
  }, []);

  return (
    <div className='FactionStats'>
      {error ?
        <div>Failed to fetch faction users :(</div>
      : userStats.length > 0 ? <table cellSpacing='0'>
        <thead>
          <tr><th>User</th><th>Level</th><th>Xanax</th><th>Cans</th><th>LSD</th><th>Refills</th><th>Networth</th><th>Ranked War Hits</th><th>Strength</th><th>Defense</th><th>Speed</th><th>Dexterity</th><th>Total</th></tr>
          <tr>{currentUserStats}</tr>
        </thead>
        <tbody>{userStats}</tbody>
      </table> :
        <div>
          <Loader />
        </div>
      }
    </div>
  );
}

export default FactionStats;

function calcColour(val1, val2) {
  if(val1 > val2 * 1.1) {
    return 'red';
  } else if(val1 < val2 * 0.9) {
    return 'green';
  }

  return 'white';
}