import React, { useState } from 'react';
import './LoginWrapper.css';
import { useAuth } from './AuthProvider';

function LoginWrapper({ children }: { children: React.ReactNode }) {
  const [key, setKey] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState(false);
  const auth = useAuth();

  async function handleSubmit(e) {
    e.preventDefault();
  
    setSubmitting(true);

    const loginResult = await fetch('https://torn-tracker-api.azure-api.net/torn-tracker-api/auth',{
      method: 'POST',
      body: JSON.stringify({ key }),
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if(loginResult.ok) {
      let token = await loginResult.json();

      window.localStorage.setItem('torn-key', key);
      window.localStorage.setItem('auth-token', JSON.stringify(token));

      auth.setTornKey(key);
      auth.setAuthToken(token);
    } else {
      setError(true);
      setSubmitting(false);
    }
  }

  return (
    auth.authToken ? <>{children}</>  :
    <div className='LoginWrapper'>
      <div className='LoginWrapper__content'>
        <header>Torn Tracker - Login</header>

        <form className='LoginWrapper__form' onSubmit={ handleSubmit }>
          <label>API Key</label>
          <input type='text' value={key} onChange={e => { setKey(e.target.value) }} disabled={ submitting }/>
          <button  disabled={ submitting }>Login</button>
        </form>
        {error ? <span className='LoginWrapper__error'>Unable to login</span> : null}
        <span className='LoginWrapper__key-link'><a href='https://www.torn.com/preferences.php#tab=api?step=addNewKey&title=TBA%20-%20Torn%20Tracker&user=basic,personalstats,battlestats' target='_blank'>Click to generate Torn API key</a></span>
      </div>
    </div>
  );
}

export default LoginWrapper;
