import React from 'react';
import './Loader.css';

function Loader({text}:LoaderProps) {

  return (
    <span className='Loader'>
      {text ? text : null}
    </span>
  );
}

export default Loader;

interface LoaderProps {
  text?: string;
}