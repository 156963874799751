import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import './Faction.css';
import FactionNav from './FactionNav';

function Faction() {
  return (
    <div className='Faction'>
      <div className='Faction__content'>
        <Outlet />
      </div>
    </div>
  );
  /*
  return (
    <div className='Faction'>
      <FactionNav />
      <div className='Faction__content'>
        <Outlet />
      </div>
    </div>
  );*/
}

export default Faction;
