import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Faction from './faction/Faction';
import FactionStatus from './faction/FactionStatus';
import FactionStats from './faction/FactionStats';
import { AuthProvider } from './auth/AuthProvider';
import LoginWrapper from './auth/LoginWrapper';
import FactionActivity from './faction/FactionActivity';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'faction',
        element: <Faction />,
        children: [
          {
            index: true,
            element: <FactionStatus />
          },
          {
            path: 'status',
            element: <FactionStatus />
          },
          {
            path: 'stats',
            element: <FactionStats />
          },
          {
            path: 'activity',
            element: <FactionActivity />
          }
        ]
      }
    ]
  },
]);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  //<React.StrictMode>
  <AuthProvider>
    <LoginWrapper>
      <RouterProvider router={router} />
    </LoginWrapper>
  </AuthProvider>
  //</React.StrictMode>
);
