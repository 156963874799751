import React, { createContext, useContext, useEffect, useState } from 'react';

export const AuthContext = createContext<AuthContextInterface | null>(null);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [tornKey, setTornKey] = useState<null|string>(null);
  const [authToken, setAuthToken] = useState<null|{ token: string; name: string; id: number }>(null);
  
  useEffect(() => {
    setTornKey(window.localStorage.getItem('torn-key'));
    const storageToken = window.localStorage.getItem('auth-token');
    setAuthToken(storageToken ? JSON.parse(storageToken) : null);
  }, []);
  

  //if(tornKey)

  return <AuthContext.Provider value={{tornKey, setTornKey, authToken, setAuthToken}}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);

  if(context == null) {
    throw 'AuthContext is null';
  }

  return context;
}

interface AuthContextInterface {
  tornKey: string | null;
  setTornKey: React.Dispatch<React.SetStateAction<string | null>>;
  authToken: { token: string; name: string; id: number } | null;
  setAuthToken: React.Dispatch<React.SetStateAction<{ token: string; name: string; id: number } | null>>;
}