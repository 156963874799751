import React from 'react';
import './App.css';
import Header from './Header';
import Nav from './Nav';
import Content from './Content';
import { Outlet, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import FactionNav from './faction/FactionNav';

function App() {
  console.log(window.location.pathname);
  /*if(window.location.pathname == '/' || window.location.pathname == '/faction') {

    window.location.pathname = '/faction/stats';
  }*/

  return (
      <div className='App'>
        <Header />
        <FactionNav />
        <Outlet />
      </div>
  );
}

export default App;
