import React, { useEffect, useState } from 'react';
import { useAuth } from '../auth/AuthProvider';
import Loader from '../Loader';
import User from '../User';
import './FactionActivity.css';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';

function FactionActivity() {
  const [userActivity, setUserActivity] = useState([]);
  const [graphData, setGraphData] = useState<any[]>([]);
  const [userList, setUserList] = useState<string[]>([]);
  const [error, setError] = useState(false);
  const auth = useAuth();

  useEffect(() => {

    const fetchData = async () => {
      console.log('Running fetchData');
      let dataRes = await fetch('https://torn-tracker-api.azure-api.net/torn-tracker-api/factions/42055/userActivity', {
        headers: { 'authorization': auth.authToken!.token}
      });
      
      if(dataRes.ok) {
        let data = await dataRes.json();
        let graph: any[] = [];
        let users: string[] = [];

        for(const user of data) {
          let hourlySum = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
          let hourlyCount = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];

          users.push(user.name);

          let cumulativeIndex = 0;

          for(const day in user.activity) {
            for(let i = 0; i < user.activity[day].length; i++) {
              if(user.activity[day][i] != null) {
                hourlySum[i] += user.activity[day][i];
                hourlyCount[i]++;

                if(graph[cumulativeIndex] == null) {
                  graph[cumulativeIndex] = {
                    name: (i < 10 ? `0${i}:00` : `${i}:00`)
                  }
                }

                graph[cumulativeIndex][user.name] = user.activity[day][i];
                cumulativeIndex++;
              }
              
            }
          }

          let averageActivity: number[] = [];

          for(let i = 0; i < hourlySum.length; i++) {
            averageActivity.push(Math.round(hourlySum[i] / hourlyCount[i]));
          }

          user.averageActivity = averageActivity;
        }

        console.log(data);

        let table = data.map(d => <tr key={d.id}>
          <td><a href={`https://www.torn.com/profiles.php?XID=${d.id}`} target='_blank'>{d.name} [{d.id}]</a></td>
          <td>{d.level}</td>
          {d.averageActivity.map(a => !Number.isNaN(a) ? <td style={{backgroundColor: '#' + ((a == 0) ? 'C1121F' : interpolateColour('FB5607', '4F772D', Math.min(1,(a / 100 * 4))))}}>{a}%</td> : <td></td>)}
        </tr>);

        setUserActivity(table);
        setGraphData(graph);
        setUserList(users);
        console.log(graph);

      } else {
        setError(true);
      } 
    }

    fetchData();
    
  }, []);

  return (
    <div className='FactionActivity'>
      {error ?
        <div>Failed to fetch faction users :(</div>
      : userActivity.length > 0 ? <table cellSpacing='0'>
        <thead>
          <tr><th>User</th><th>Level</th><th>00:00</th><th>01:00</th><th>02:00</th><th>03:00</th><th>04:00</th><th>05:00</th><th>06:00</th><th>07:00</th><th>08:00</th><th>09:00</th><th>10:00</th><th>11:00</th><th>12:00</th><th>13:00</th><th>14:00</th><th>15:00</th><th>16:00</th><th>17:00</th><th>18:00</th><th>19:00</th><th>20:00</th><th>21:00</th><th>22:00</th><th>23:00</th></tr>
        </thead>
        <tbody>{userActivity}</tbody>
      </table> :
        <div>
          <Loader />
        </div>
      }
      {error ? null :
      <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={500}
        height={400}
        data={graphData}
        margin={{
          top: 50,
          right: 10,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis tick={{ fill: '#eee' }} dataKey="name" label={"Hour"} />
        <YAxis tick={{ fill: '#eee' }} label={{ value: "Activity minutes", angle: -90 }} />
        {/*<Tooltip />
        <Legend align="right" layout="vertical" width={200} margin={{
          top: 0,
          right: 0,
          left: 400,
          bottom: 0,
        }}/>*/}
        {userList.map(user => <Area type="monotone" dataKey={user} stackId="1" stroke="#8884d8" fill="#8884d8" />)}
      </AreaChart></ResponsiveContainer>
      }

    </div>
  );
}

export default FactionActivity;

function interpolateColour(c0, c1, f){
  c0 = c0.match(/.{1,2}/g).map((oct)=>parseInt(oct, 16) * (1-f))
  c1 = c1.match(/.{1,2}/g).map((oct)=>parseInt(oct, 16) * f)
  let ci = [0,1,2].map(i => Math.min(Math.round(c0[i]+c1[i]), 255))
  return ci.reduce((a,v) => ((a << 8) + v), 0).toString(16).padStart(6, "0")
}