import React from 'react';
import { NavLink } from 'react-router-dom';
import './FactionNav.css';

function FactionNav() {
  /*
  return (
    <nav className='FactionNav'>
      <ul className='FactionNav__list'>
        <li><NavLink to='/faction' end>Status</NavLink></li>
        <li><NavLink to='/faction/stats'>Stats / Spies</NavLink></li>
        <li><NavLink to='/faction/matchups'>Matchups</NavLink></li>
        <li><NavLink to='/faction/activity'>Activity</NavLink></li>
        <li><NavLink to='/faction/flights'>Flights</NavLink></li>
      </ul>
    </nav>
  );*/

  return (
    <nav className='FactionNav'>
      <ul className='FactionNav__list'>
        <li><NavLink to='/faction/stats'>Stats / Spies</NavLink></li>
        <li><NavLink to='/faction/activity'>Activity</NavLink></li>
      </ul>
    </nav>
  );
}

export default FactionNav;
