import React, { useEffect, useState } from 'react';
import { useAuth } from '../auth/AuthProvider';
import Loader from '../Loader';
import User from '../User';
import './FactionStatus.css';

function FactionStatus() {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(false);
  const auth = useAuth();

  useEffect(() => {

    const fetchData = async () => {
      let dataRes = await fetch('https://torn-tracker-api.azure-api.net/torn-tracker-api/factions/42055/userStates', {
        headers: { 'authorization': auth.authToken!.token}
      });
      
      if(dataRes.ok) {
        let data = await dataRes.json();

        setUsers(data.map(user => <User user={user} key={user.id} />));

      } else {
        setError(true);
      } 
    }

    fetchData();
    
  }, []);

  return (
    <div className='FactionStatus'>
      {error ?
        <div>Failed to fetch faction users :(</div>
      : users.length > 0 ? users :
        <div>
          <Loader />
        </div>
      }
    </div>
  );
}

export default FactionStatus;
